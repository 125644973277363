.banner {
    background-image: url('https://images.pexels.com/photos/276452/pexels-photo-276452.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    height: 35vh;
    position: relative;
  }
  
  #overlay img { display: none; }

  #overlay.currently-loading img { 
      display: block; 
      width: 100%;
      height: 100%;
  }

  .title{
    position:absolute;
    bottom: 6rem;
    color: #fff;
    font-weight: 700;
  }
  .subtitle{
    position:absolute;
    bottom: 3.5rem;
    color: #fff;
  }
 .btn-join{
  border: #fff 1px solid !important;
  background-color: none !important;
  color : #fff !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
 
 }
 .btn-group:hover,
 .btn-group:focus,
 .btn-group:active{
  border: #fff 1px solid !important;
  background-color: none !important;
  color : #fff !important;
 }
 .txt-white{
  color: #fff;
 }

.ml-auto{
  margin-left: auto !important;
}
.mx-3{
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}