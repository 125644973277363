.img {
    height: 250px;
}

.text-left {
    position:static;
}

.text-right {
    text-align: right;
}

h5 {
    font-size: 1.1em !important;
}

h4 {
    font-size: 1.2em !important;
    font-weight: 700 !important;
    line-height: 1.7rem !important;
}

p {
    color: #868E96;
    font-size: 1.1em;
    font-weight: 400;
}

.ml-auto {
    margin-left: auto;
}

.ml-3 {
    margin-left: 2rem;
}

.ml-1 {
    margin-left: 0.75rem;
}

.mt-2 {
    margin-top: 2rem;
}

.writer {
    font-weight: 700;
}

.gray-txt {
    color: #495057;
    font-weight: 500;
    font-size: 0.75em;
}

.txt-bold {
    font-weight: 700;
    font-size: 0.9em;
}

.download-btn {
    width: 100%;
    border: #CED4DA solid 1px !important;
    background-color: #ced4da2f !important;
    color: #e6b000d5 !important;
    font-weight: 500 !important;
    font-size: 0.85em !important;
}

.maps-btn {
    width: 100%;
    border: #CED4DA solid 1px !important;
    background-color: #ced4da2f !important;
    color: #4285F4 !important;
    font-weight: 500 !important;
    font-size: 0.85em !important;
}

.house-btn {
    width: 100%;
    border: #CED4DA solid 1px !important;
    background-color: #ced4da2f !important;
    color: #07c04e !important;
    font-weight: 500 !important;
    font-size: 0.85em !important;
}