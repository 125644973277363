.navBarIcons{
    font-size: 30px;
  }
  
  @media only screen and (max-width: 400px) {
    .navBarIcons {
      font-size: 20px;
    }
  }

